<template>
    <div>
        <nav id="nav">
            <div class="navbar my-container">
                <div class="nav-logo">
                    <svg id="Logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="155.595" height="30.969" viewBox="0 0 155.595 30.969">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_1316" data-name="Rectangle 1316" width="155.595" height="30.969" fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Group_2100" data-name="Group 2100" transform="translate(0 0)">
                            <g id="Group_2100-2" data-name="Group 2100" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <path id="Path_2788" data-name="Path 2788" d="M106.647,5.186H93.667A9.152,9.152,0,0,0,84.991,12.5l-1.5,8.49a6,6,0,0,0,6.1,7.318h12.978a9.15,9.15,0,0,0,8.676-7.318l1.5-8.49a6,6,0,0,0-6.1-7.318m-2.788,15.808H90.881l1.494-8.49h12.981Z" transform="translate(42.74 2.658)" fill="#31006f"/>
                            <path id="Path_2789" data-name="Path 2789" d="M68.565,5.186h-9.55A9.109,9.109,0,0,0,50.407,12.5L47.619,28.31h7.318L57.725,12.5h9.55Z" transform="translate(24.409 2.658)" fill="#31006f"/>
                            <path id="Path_2790" data-name="Path 2790" d="M85.466,7.665H72.574L73.864.349H66.571L65.28,7.665l-1.29,7.318-1.5,8.49a5.963,5.963,0,0,0,6,7.318h12.89a9.094,9.094,0,0,0,8.584-7.318l1.5-8.49a5.962,5.962,0,0,0-6-7.318M82.678,23.473H69.788l1.5-8.49H84.176Z" transform="translate(31.974 0.178)" fill="#31006f"/>
                            <path id="Path_2791" data-name="Path 2791" d="M32.152,14.908,33.43,7.662H23.414L24.7.349H17.386l-4.01,22.733-.074.428a5.941,5.941,0,0,0,6,7.282H29.351l1.29-7.318H20.625l1.511-8.566Z" transform="translate(6.76 0.178)" fill="#31006f"/>
                            <path id="Path_2792" data-name="Path 2792" d="M0,12.5H17.334l1.29-7.318H1.29Z" transform="translate(0 2.658)" fill="#e80505"/>
                            <path id="Path_2793" data-name="Path 2793" d="M57.734,5.186H50.417L47.63,20.994H34.92L37.708,5.186H30.381L29.091,12.5H29.1l-1.5,8.49A5.97,5.97,0,0,0,33.63,28.31H46.34a9.108,9.108,0,0,0,8.607-7.316L56.986,9.435Z" transform="translate(14.091 2.658)" fill="#31006f"/>
                            <path id="Path_2794" data-name="Path 2794" d="M101.947,0a2.935,2.935,0,0,1,1.407.369,2.631,2.631,0,0,1,1.069,1.059,2.868,2.868,0,0,1,.006,2.857,2.684,2.684,0,0,1-1.059,1.06,2.871,2.871,0,0,1-2.848,0,2.7,2.7,0,0,1-1.06-1.06,2.875,2.875,0,0,1-.38-1.422,2.91,2.91,0,0,1,.384-1.435A2.647,2.647,0,0,1,100.539.369,2.937,2.937,0,0,1,101.947,0m0,.473a2.431,2.431,0,0,0-1.175.31,2.219,2.219,0,0,0-.892.883,2.373,2.373,0,0,0-.006,2.382,2.247,2.247,0,0,0,.886.885,2.388,2.388,0,0,0,2.375,0,2.249,2.249,0,0,0,.883-.885,2.392,2.392,0,0,0-.006-2.382,2.2,2.2,0,0,0-.892-.883,2.433,2.433,0,0,0-1.172-.31M100.69,4.445V1.366h1.059a2.573,2.573,0,0,1,.785.085.764.764,0,0,1,.387.3.776.776,0,0,1,.144.452.821.821,0,0,1-.241.588.951.951,0,0,1-.638.281.842.842,0,0,1,.262.163,3.5,3.5,0,0,1,.455.61l.375.6h-.607L102.4,3.96a2.782,2.782,0,0,0-.478-.687.677.677,0,0,0-.44-.136h-.292V4.445Zm.5-1.733h.6a.975.975,0,0,0,.59-.129.421.421,0,0,0,.157-.342.4.4,0,0,0-.213-.372,1.266,1.266,0,0,0-.572-.088h-.566Z" transform="translate(50.788 0)" fill="#31006f"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="buttons">
                    <button class="btn btn1">
                        <router-link to="/login" class="">{{$t('login')}}</router-link>
                    </button>
                </div>
            </div>
        </nav>
        <section id="pages-header" class="my-container">
            <div class="title-pages title-terms-conditions">
                <h3>{{ $t('termsCondition') }}</h3>
                <h4>يرجي قراءة الشروط والأحكام لتأكيد ذلك</h4>
                <p v-html="terms_and_conditions.page.content"></p>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters('dataList', ['terms_and_conditions']),
    },
    methods: {
        ...mapActions('dataList', ['fetchTermsAndCondition']),
    },
    created(){
        this.fetchTermsAndCondition()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';

.title-page{
    margin-bottom: 1.3rem;
    margin-top: 1.3rem;
}
.line{
    border-bottom: 0.03125rem solid #70707061;
    opacity: 50%;
    margin-bottom: 1.3rem;
}
P{
    color: #212121;
    font-weight: 600;
    line-height: 2.5rem;
}
.my-container
{
    padding: 0px 170px !important;
    width: 100% !important;
    margin: auto;
}
#nav
{
    box-shadow: 0px 2px 8px #0000000A;
    background-color: #FFF;
    padding: 21px 0px;
    width: 100%;
}
.navbar
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn{
    color: $mainColor1;
    width: fit-content;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    border: none;
}
.btn1:hover{
    background-color: $mainColor1;
}
.btn1:hover a{
    color: #FFF;
}
.btn1{
    background-color: $mainColor2;
    transition: .3s;
    height: 38px;
}
.title-terms-conditions{
    margin-bottom: 64px;
}
.title-terms-conditions h4{
    font-size: 32px;
    line-height: 47px;
}
.title-pages p, .title-pages p p, .title-pages p p span{
    color: #212121 !important;
    font-size: 18px !important;
    line-height: 35px !important;
    font-weight: 600 !important;
    white-space: pre-line !important;
    background-color: transparent !important;
    font-family: 'Cairo' !important;
}
.title-terms-conditions p{
    font-size: 16px;
    text-align: left;
    line-height: 32px;
}
#pages-header{
    padding-top: 71px !important;
    margin-bottom: 32px;
}
.title-pages{
    background-color: $seconderyColorTermsConditions;
    border-radius: 4px;
    padding: 51px 35px 27px 35px;
    text-align: center;
    position: relative;
}
.title-pages h3{
    color: #FFF;
    font-size: 36px;
    font-weight: bold;
    line-height: 60px;
    position: absolute;
    padding: 5px 30px;
    top: -39px;
    left: 50%;
    transform: translateX(-50%);
}
.title-pages h3::before{
    content: '';
    background-color: $mainColorTermsConditions;
    transform: skew(-20deg);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
	left: 0;
    z-index: -1;
}
.title-pages h4{
    color: $mainColor1;
    font-size: 32px;
    line-height: 47px;
    margin-bottom: 8px;
    font-weight: bold;
}
</style>